import { DASHBOARD, MANAGER_DASHBOARD, SERVICE_PROVIDER_DASHBOARD } from "./routes";
import harvestIcon from "../assets/harvest.png";
import farmerIcon from "../assets/farmer.png";
import analyticsIcon from "../assets/analytics.png";
import qualityIcon from "../assets/quality-icon.png";
import weatherIcon from "../assets/weather.png";
import damsWater from "../assets/dams-water.png";

import qualityGreen from "../assets/quality-green.png";
import harvestGreen from "../assets/harvest-green.png";
import farmerGreen from "../assets/farmer-green.png";
import analyticsGreen from "../assets/analytics-green.png";
import weatherGreen from "../assets/weather-green.png";
import damsWaterGreen from "../assets/dams-water-green.png";

import { getLogisticsTitle } from "../utils";

export const TOKEN = "";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TASK = "SET_TASKS";
export const MIN_PASSWORD_LENGTH = 8;
export const MIN_VERIFICATION_CODE_LENGTH = 4;
export const PAGINATION = 10;
export const MAX_PHONENUMBER_LENGTH = 20;

// authentification

export const PURCHASER = 1;
export const MANAGER = 2;
export const PROVIDER = 3;

// map

export const HIDE_SHOWALL_ROWS_COUNT = 4;

// dams-water-level
export const RESIZABLE_MAPS_ENABLED = false;

//sidebar

export const HARVEST_AND_LOGISTICS_INDEX = 0;
export const FARMER_FOCUS_INDEX = 1;
export const ANALYTICS_INDEX = 2;

export const AVAILABLE_PURCHASER_OPTIONS = [
    HARVEST_AND_LOGISTICS_INDEX, 
    FARMER_FOCUS_INDEX,
    ANALYTICS_INDEX
];

export const SIDEBAR_LINKS = [
    { id: HARVEST_AND_LOGISTICS_INDEX, pathname: DASHBOARD, title: "Harvest and Logistics", icon: harvestIcon, icardaIcon: harvestGreen},
    { id: FARMER_FOCUS_INDEX, pathname: DASHBOARD, title: "Farmer focus", icon: farmerIcon, icardaIcon: farmerGreen},
    { id: ANALYTICS_INDEX, pathname: DASHBOARD, title: "Analytics", icon: analyticsIcon, icardaIcon: analyticsGreen},
  ];

//manager-sidebar
export const QUALITY_COMPAIGN_INDEX = 3;
export const PLANT_DEVELOPMENT = 4;
export const MANAGER_FARMER_INDEX = 5;
export const MANAGER_ANALYTICS_INDEX = 6;
export const MANAGER_WEATHER_INDEX = 7;
export const DAM_WATER_INDEX = 8;
export const PROFILE_INDEX = 10;

export const AVAILABLE_MANAGER_OPTIONS = [
    QUALITY_COMPAIGN_INDEX, 
    MANAGER_FARMER_INDEX,
    PLANT_DEVELOPMENT,
    DAM_WATER_INDEX,
    MANAGER_WEATHER_INDEX,
    ANALYTICS_INDEX,
    MANAGER_ANALYTICS_INDEX
]

export const MANAGER_SIDEBAR_LINKS = [
    { id: QUALITY_COMPAIGN_INDEX, pathname: MANAGER_DASHBOARD, title: "Quality of the campaign", icon: qualityIcon, icardaIcon: qualityGreen},
    { id: PLANT_DEVELOPMENT, pathname: MANAGER_DASHBOARD, title: "Plant development (Zadoks scale)", icon: harvestIcon, icardaIcon: harvestGreen},
    { id: MANAGER_FARMER_INDEX, pathname: MANAGER_DASHBOARD, title: "Farmer focus", icon: farmerIcon, icardaIcon: farmerGreen},
    { id: MANAGER_ANALYTICS_INDEX, pathname: MANAGER_DASHBOARD, title: "Analytics", icon: analyticsIcon, icardaIcon: analyticsGreen},
    { id: MANAGER_WEATHER_INDEX, pathname: MANAGER_DASHBOARD, title: "Weather", icon: weatherIcon, icardaIcon: weatherGreen},
    { id: DAM_WATER_INDEX, pathname: MANAGER_DASHBOARD, title: "Dam water levels", icon: damsWater, icardaIcon: damsWaterGreen},
  ];

//service-provider-sidebar

export const BOOKING_INDEX = 0;
export const SEEDERS = 1;


export const SERVICE_PROVIDER_SIDEBAR_LINKS = [
    { id: BOOKING_INDEX, pathname: SERVICE_PROVIDER_DASHBOARD, title: "Booking", icon: qualityIcon, icardaIcon: qualityGreen},
    { id: SEEDERS, pathname: SERVICE_PROVIDER_DASHBOARD, title: "Seeders", icon: harvestIcon, icardaIcon: harvestGreen},
  ];

//api-form-steps

export const COMMUNE_API_FORM_STEP = 1;
export const COMMUNE_API_GRAPH_STEP = 2;
export const COMMUNE_API_START_YEAR = 1985;
export const MOROCCO_SEASON_MONTH_START = 11;
export const MOROCCO_SEASON_MONTH_END = 7;

// profile

export const profileSettingsOptions = [
    {id: 0, text: "Personal information"},
    {id: 1, text: "Password"},
];

//

//collection-points regions ids

export const COLLECTION_POINTS_REGION_IDS = {
    "Benslimane": [6,5,4],
    "Finalog": [6,5,4,7],
    "Meknès": [3,4,5],
    "Cam Sidi El Aidi": [6,5,4,7],
    "Maymouna grain Safi ": [6,7]
}

//region collection-points ids

export const REGION_MEKNES_CP_IDXS = [0,1,4];
export const REGION_RABAT_CP_IDXS = [0,1,2,3,4,5];
export const REGION_BENI_CP_IDXS = [0,1,2,4,5];
export const REGION_CASABLANCA_CP_IDXS = [0,1,2,3,5,6];
export const REGION_MARRAKECH_CP_IDXS = [0,1,5,6];

export const REGION_MEKNES_CP_IDS = [1];
export const REGION_RABAT_CP_IDS = [1,2,3,5];
export const REGION_BENI_CP_IDS = [1,2,5];
export const REGION_CASABLANCA_CP_IDS = [2,3,4,5];
export const REGION_MARRAKECH_CP_IDS = [4,5];

export const REGIONS_COLLECTION_POINTS = {
    '3': [1],
    '4': [1,2,3,5],
    '5': [1,2,5],
    '6': [2,3,4,5],
    '7': [4,5]
}

//legend-graph-options

export const harvestForecastColorOptions = [
    { value: "0-0.5 T/ha", color: "#C00000"},
    { value: "0.5-1.0 T/ha", color: "#DC4500"},
    { value: "1.0-1.5 T/ha", color: "#F88A00"},
    { value: "1.5-2.0 T/ha", color: "#FFBC2A"},
    { value: "2.0-2.5 T/ha", color: "#FFE963"},
    { value: "2.5-3.0 T/ha", color: "#E9FF85"},
    { value: "3.0-3.5 T/ha", color: "#BCFF92"},
    { value: "3.5-4.0 T/ha", color: "#8AEF9E"},
    { value: "4.0-4.5 T/ha", color: "#45B1AC"},
    { value: "4.5-5.0 T/ha", color: "#0073BA"},
];

export const plantedAreaColorOptions = [
    { value: "0-3 %", color: "#C00000"},
    { value: "3-6 %", color: "#DC4500"},
    { value: "6-9 %", color: "#F88A00"},
    { value: "9-12 %", color: "#FFBC2A"},
    { value: "12-15 %", color: "#FFE963"},
    { value: "15-18 %", color: "#E9FF85"},
    { value: "18-21 %", color: "#BCFF92"},
    { value: "21-24 %", color: "#8AEF9E"},
    { value: "24-27 %", color: "#45B1AC"},
    { value: "27-30 %", color: "#0073BA"},
];

export const cropRiskColorOptions = [
    { value: "0.0-0.2", color: "#007f3f"},
    { value: "0.2-0.4", color: "#a6d96a"},
    { value: "0.4-0.6", color: "#ffffc0"},
    { value: "0.6-0.8", color: "#ff7f3f"},
    { value: "0.8-1.0", color: "#ab0000"},
];

export const weatherTemperatureColorOptions = [
    { value: "-4ºC and lower", color: "#0066FF"},
    { value: "-4ºC to -2ºC", color: "#66CCFF"},
    { value: "-2ºC to 0ºC", color: "#C3E1FF"},
    { value: "0ºC to 2ºC", color: "#FFCDCD"},
    { value: "2ºC to 4ºC", color: "#FF7F7F"},
    { value: "4ºC and higher", color: "#C00000"},
];

export const weatherPrecipitationColorOptions = [
    { value: "-50mm and lower", color: "#8B4513"},
    { value: "-50mm to -25mm", color: "#FFA040"},
    { value: "-25 to 0mm", color: "#FFEE33"},
    { value: "0mm to 25mm", color: "#C3E1FF"},
    { value: "25mm to 50mm", color: "#66CCFF"},
    { value: "50mm and higher", color: " #0066FF"},
];

export const NdviColorOptions = [
    { range: "0", value: "0 - 0.1", color: "#a50026", popup: 'Bare soil'},
    { range: "0.1", value: "0.1 - 0.2", color: "#d73027", popup: 'Almost absent canopy cover'},
    { range: "0.2", value: "0.2 - 0.3", color: "#fdae61", popup: 'Very low canopy cover'},
    { range: "0.3", value: "0.3 - 0.4", color: "#fee08b", popup: 'Low canopy cover, low vigour or very low canopy cover, high vigour'},
    { range: "0.4", value: "0.4 - 0.5", color: "#ffffbf", popup: 'Mid-low canopy cover, low vigour or low canopy cover, high vigour'},
    { range: "0.5", value: "0.5 - 0.6", color: "#d9ef8b", popup: 'Average canopy cover, low vigour or mid-low canopy cover,'},
    { range: "0.6", value: "0.6 - 0.7", color: "#a6d96a", popup: 'Mid-high canopy cover, low vigour or average canopy cover, high vigour'},
    { range: "0.7", value: "0.7 - 0.8", color: "#66bd63", popup: 'High canopy cover, high vigour'},
    { range: "0.8", value: "0.8 - 0.9", color: "#1a9850", popup: 'Very high canopy cover, very high vigour'},
    { range: "0.9", value: "0.9 - 1", color: "#1a9641", popup: 'Total canopy cover, very high vigour'},
];

export const NdmiColorOptions = [
    { range: "-1", value: "-1 - -0.8", color: "#ab0000", popup: 'Bare soil'},
    { range: "-0.8", value: "-0.8 - -0.6", color: "#ff0000", popup: 'Almost absent canopy cover'},
    { range: "-0.6", value: "-0.6 - -0.4", color: "#ff7f00", popup: 'Very low canopy cover'},
    { range: "-0.4", value: "-0.4 - -0.2", color: "#ffbd7f", popup: 'Low canopy cover, dry or very low canopy cover, wet'},
    { range: "-0.2", value: "-0.2 - 0", color: "#ffff7f", popup: 'Mid-low canopy cover, high water stress or low canopy cover, low water stress'},
    { range: "0", value: "0 - 0.2", color: "#c3ff4c", popup: 'Average canopy cover, high water stress or mid-low canopy cover, low water stress'},
    { range: "0.2", value: "0.2 - 0.4", color: "##7fffbf", popup: 'Mid-high canopy cover, high water stress or average canopy cover, low water stress'},
    { range: "0.4", value: "0.4 - 0.6", color: "#7fffff", popup: 'High canopy cover, no water stress'},
    { range: "0.6", value: "0.6 - 0.8", color: "#71eaf8", popup: 'Very high canopy cover, no water stress'},
    { range: "0.8", value: "0.8 - 1", color: "#003fbf", popup: 'Total canopy cover, very high vigour'},
];

export const plantDevelopmentColorOptions = [
    { range: "0", value: "0-10 Germination", color: "#8B4513", popup: 'Germination'},
    { range: "10", value: "10-20 Seeding", color: "#C7FEC7", popup: 'Seeding'},
    { range: "20", value: "20-30 Tilering", color: "#9AF39A", popup: 'Tilering'},
    { range: "30", value: "30-40 Stem elongation", color: "#6DE86D", popup: 'Stem elongation'},
    { range: "40", value: "40-50 Booting", color: "#3FDD3F", popup: 'Booting'},
    { range: "50", value: "50-60 Ear emergence", color: "#12D112", popup: 'Ear emergence'},
    { range: "60", value: "60-70 Flowering", color: "#55DE2A", popup: 'Flowering'},
    { range: "70", value: "70-80 Milk development", color: "#E3F971", popup: 'Milk development'},
    { range: "80", value: "80-90 Doug development", color: "#FFEE33", popup: 'Doug development'},
    { range: "90", value: "90-100 Ripening", color: "#FFA040", popup: 'Ripening'},
];

//dam-filters

export const MONTH_OPTIONS = [
    {key: 0, text: "November", value: 11},
    {key: 1, text: "December", value: 12},
    {key: 2, text: "January", value: 1},
    {key: 3, text: "February", value: 2},
    {key: 4, text: "March", value: 3},
    {key: 5, text: "April", value: 4},
    {key: 6, text: "May", value: 5},
    {key: 7, text: "June", value: 6},
    {key: 8, text: "July", value: 7},
];

//weather-compaign

export const WEATHER_MONTH_OPTIONS = [
    {key: 1, text: "November", value: 11},
    {key: 2, text: "December", value: 12},
    {key: 3, text: "January", value: 1},
    {key: 4, text: "February", value: 2},
    {key: 5, text: "March", value: 3},
    {key: 6, text: "April", value: 4},
    {key: 7, text: "May", value: 5},
    {key: 8, text: "June", value: 6},
    {key: 9, text: "July", value: 7},
    {key: 10, text: "August", value: 8},
    {key: 11, text: "September", value: 9},
    {key: 12, text: "October", value: 10},
];

// map-filters

export const MONTH_NAMES = [
    {name: "November", value: 11},
    {name: "December", value: 12},
    {name: "January", value: 1},
    {name: "February", value: 2},
    {name: "March", value: 3},
    {name: "April", value: 4},
    {name: "May", value: 5},
    {name: "June", value: 6},
    {name: "July", value: 7},
];

export const filtersOptions = {
    dataFor: [
        { key: 0, text: 'MOROCCO', value: 'MOROCCO' },
    ],
    season: [
        { key: 0, text: '2023-2024', value: 2024 },
        { key: 1, text: '2022-2023', value: 2023 },
        { key: 2, text: '2021-2022', value: 2022 }
    ],
    filter: [
        { key: 0, text: 'Harvest Forecast', value: 'Harvest Forecast' },
        { key: 1, text: 'Planted Area', value: 'Planted Area' },
        { key: 2, text: 'Crop Risk', value: 'Crop Risk' },
    ],
    managerFilter: [
        { key: 0, text: 'Harvest Forecast', value: 'Harvest Forecast' },
        { key: 1, text: 'Planted Area', value: 'Planted Area' },
        { key: 2, text: 'Crop Score', value: 'Crop Score', disabled: true },
        { key: 3, text: 'NDVI', value: 'NDVI' },
        { key: 4, text: 'NDMI', value: 'NDMI' },
        { key: 5, text: 'Crop Risk', value: 'Crop Risk' },
    ],
    weatherFilter: [
        { key: 0, text: 'Temperature', value: 1 },
        { key: 1, text: 'Precipitation', value: 2},
    ],
    cropOptions: [
        { key: 0, text: 'Cereals', value: 'Cereals', requestName: 'CEREALS' },
        { key: 1, text: "Pulses", value: "Pulses", requestName: 'PULSES' },
        { key: 2, text: "Oily seeds", value: "Oily seeds", requestName: 'OILY_SEEDS' },
        { key: 3, text: "Fruits", value: "Fruits", requestName: 'FRUITS' },
        { key: 4, text: "Fallow", value: "Fallow", requestName: 'FALLOW' },
    ],
    сonventionalCropOptions: [
        { key: 0, text: 'Cereals', value: 'Cereals', requestName: 'CEREALS' },
        { key: 1, text: "Pulses", value: "Pulses", requestName: 'PULSES' },
        { key: 2, text: "Oily seeds", value: "Oily seeds", requestName: 'OILY_SEEDS' },
        { key: 3, text: "Fruits", value: "Fruits", requestName: 'FRUITS' },
        { key: 4, text: "Fallow", value: "Fallow", requestName: 'FALLOW' },
        { key: 5, text: "Unidentified", value: "unidentified", requestName: 'unidentified' },
    ],
    month: [
        { key: 0, text: MONTH_NAMES[new Date().getMonth() - 1], value: 0 }
    ],
    collectionalPoint: [
        { key: 0, text: getLogisticsTitle().default, value: getLogisticsTitle().default },
        { key: 1, text: getLogisticsTitle().title, value: getLogisticsTitle().title },
        { key: 2, text: "Benslimane", value: "Benslimane" },
        { key: 3, text: "Finalog", value: "Finalog" },
        { key: 4, text: "Meknès", value: "Meknès" },
        { key: 5, text: "Cam Sidi El Aidi", value: "Cam Sidi El Aidi" },
        { key: 6, text: "Maymouna grain Safi ", value: "Maymouna grain Safi " },
    ],
}

export const WEEK_OPTIONS_MAPPER = [
    { text: "Week 1", value: "1"},
    { text: "Week 2", value: "2"},
    { text: "Week 3", value: "3"},
    { text: "Week 4", value: "4"},
    { text: "Week 1 - Week 2", value: "12"},
    // { text: "Week 3 - Week 4", value: "34"},
];

//dams-water-level

export const damsLegendOptions = [
    { value: "0 - 16.7%", color: "#800020", popup: 'Current capacity'},
    { value: "16.7 - 33.3%", color: "#C00000", popup: 'Current capacity'},
    { value: "33.3 - 50%", color: "#FF7F00", popup: 'Current capacity'},
    { value: "50 - 66.7 %", color: "#FFFFBF", popup: 'Current capacity'},
    { value: "66.7 - 83.3%", color: "#00C07F", popup: 'Current capacity'},
    { value: "83.3 - 100%", color: "#00C0FF", popup: 'Current capacity'},
];

//analytics
